'use client';

import React from 'react';

import { CartProvider } from '@/context/cart-context';
import { MiniCartProvider } from '@/context/miniCart';

function AppProviders({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <CartProvider>
      <MiniCartProvider>{children}</MiniCartProvider>
    </CartProvider>
  );
}

export default AppProviders;
